import { useEffect, useMemo, useState } from 'react';
import './App.css';
import useAudio from './hooks/useAudio';
import * as musicMetadata from 'music-metadata-browser';
// import Drive from './components/Drive';
import { useAWS } from './hooks/useAws';
import useFirestore from './hooks/useFirestore';

function App() {

  const { uploadFile } = useAWS()
  const { addTrack, albums, changeAlbumId, tracks, deleteAlbumId } = useFirestore()

  const [trackIndex, setTrackNumber] = useState(-1)

  const track = useMemo(() => {
    return tracks?.[trackIndex] ?? null
  }, [trackIndex, tracks])

  useEffect(() => {
    changeTrack(track?.file)
  }, [track])

  const {
    isPlaying,
    onPlayPauseClick,
    changeTrack,
    currentPercentage,
    trackProgress,
    duration,
    onScrub,
    onScrubEnd,
    setVolume
  } = useAudio({
    src: track?.file
  })

  const trackStyling = `
  -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #fff), color-stop(${currentPercentage}, #777))
`;

  const onSelectTrack = (index) => {
    setTrackNumber(index)
  }

  const onSelectAlbum = (albumId) => {
    changeAlbumId(albumId)
  }

  const nextTrack = () => {
    const nextIndex = trackIndex + 1
    if (!tracks[nextIndex]) {
      setTrackNumber(-1)
    } else {
      setTrackNumber(nextIndex)
    }
  }

  const previousTrack = () => {
    const previousIndex = trackIndex - 1
    if (!tracks[previousIndex]) {
      setTrackNumber(-1)
    } else {
      setTrackNumber(previousIndex)
    }
  }

  const onFile = async (e) => {
    const files = Array.from(e.target?.files) ?? []

    for (const file of files) {
      const url = await uploadFile(file)

      window.requestFileSystem = window.requestFileSystem || window.webkitRequestFileSystem;

      musicMetadata.parseBlob(file).then(metadata => {

        const { duration } = metadata.format

        addTrack({ ...metadata.common, duration, file: url })
      });
    }
  }

  return (
    <div className="App">

      <div>
        <form >
          <input type="file" onChange={onFile} multiple="multiple">

          </input>
        </form>

      </div>
      {albums?.map((album) => {
        return (
          <div key={album.name}>
            {album.name}
            <button onClick={() => onSelectAlbum(album.id)}>
              Select
            </button>
            <button onClick={() => deleteAlbumId(album.id)}>
              Delete
            </button>
            <hr />
          </div>
        )
      })}

      <hr />
      <hr />
      {tracks?.map((trackItem, i) => {
        return (
          <div key={trackItem.id}>
            {track?.id === trackItem.id ? `=> ` : null}
            {trackItem.title}
            <button onClick={() => onSelectTrack(i)}>
              Play
            </button>
            <hr />
          </div>
        )
      })}
      <div className='audio-controls'>
        <button
          type="button"
          className="prev"
          aria-label="Previous"
          onClick={previousTrack}
        >
          ⏮
        </button>
        {isPlaying ? (
          <button
            type="button"
            className="pause"
            onClick={() => onPlayPauseClick(false)}
            aria-label="Pause"
          >
            ⏸
          </button>
        ) : (
          <button
            type="button"
            className="play"
            onClick={() => onPlayPauseClick(true)}
            aria-label="Play"
          >
            ▶️
          </button>
        )}

        <button
          type="button"
          className="next"
          aria-label="Next"
          onClick={nextTrack}
        >
          ⏭
        </button>
        <input
          type="range"
          value={trackProgress}
          step="1"
          min="0"
          max={duration ? duration : `${duration}`}
          className="progress"
          onChange={(e) => onScrub(e.target.value)}
          onMouseUp={onScrubEnd}
          onKeyUp={onScrubEnd}
          style={{ background: trackStyling }}
        />
        <input
          onChange={(e) => setVolume(e.target.value)}
          className="volume"
          type="range"
          orient="vertical" />

      </div>

    </div>
  );
}

export default App;
