import { useRef, useState, useEffect } from 'react'

const useAudio = ({ src }) => {

    const [isPlaying, setPlaying] = useState(false)
    const audioRef = useRef(new Audio(src));
    const intervalRef = useRef();
    const [trackProgress, setTrackProgress] = useState(0);

    const { duration } = audioRef.current;
    const currentPercentage = duration
        ? `${(trackProgress / duration) * 100}%`
        : "0%";

    const onPlayPauseClick = (val) => {
        setPlaying(val)
    }

    const changeTrack = (audioSrc) => {
        console.log(audioSrc)
        audioRef?.current?.pause();
        if (audioSrc) {
            audioRef.current = new Audio(audioSrc);
            setPlaying(true)
            audioRef?.current?.play();
            startTimer();
        }
    }

    const setVolume = (val) => {
        if (audioRef?.current) {
            if (val) {
                audioRef.current.muted = false
            }
            audioRef.current.volume = val / 100
        }
    }

    const startTimer = () => {
        clearInterval(intervalRef.current);

        intervalRef.current = setInterval(() => {
            if (audioRef.current.ended) {
                setPlaying(false);
            } else {
                setTrackProgress(audioRef.current.currentTime);
            }
        }, [500]);
    };

    useEffect(() => {
        if (isPlaying) {
            audioRef?.current?.play();
            startTimer();
        } else {
            audioRef.current.pause();
        }
    }, [isPlaying]);

    const onScrub = (value) => {
        clearInterval(intervalRef.current);
        audioRef.current.currentTime = value;
        setTrackProgress(audioRef.current.currentTime);
    };

    const onScrubEnd = () => {
        startTimer();
    };

    return {
        isPlaying,
        onPlayPauseClick,
        audioRef,
        changeTrack,
        currentPercentage,
        trackProgress,
        setVolume,
        duration,
        onScrub,
        onScrubEnd
    }
}

export default useAudio