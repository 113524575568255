import firebase from 'firebase/app'
import 'firebase/firestore'
import { nanoid } from 'nanoid';
import { useCallback, useMemo, useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { useAWS } from './useAws';


const config = {
    apiKey: "AIzaSyBtzmCF5irUxEnP-71nCumyKGl9bJjX5kY",
    authDomain: "music-lib-784d1.firebaseapp.com",
    projectId: "music-lib-784d1",
    storageBucket: "music-lib-784d1.appspot.com",
    messagingSenderId: "195721886642",
    appId: "1:195721886642:web:5c147b715b7c30ab8102ab"
};



firebase.initializeApp({
    ...config
})

const firestore = firebase.firestore()

const useFirestore = () => {
    const { uploadFile } = useAWS()
    const albumsRef = firestore.collection('albums');
    const tracksRef = firestore.collection('tracks');

    const [albumId, setAlbumId] = useState(null);
    const [albums] = useCollectionData(albumsRef, { idField: 'id' });

    const query = useMemo(() => {
        return tracksRef.where('albumId', '==', albumId)
    }, [albumId])

    const [tracks] = useCollectionData(query, { idField: 'id' });


    const changeAlbumId = (id) => {
        console.log(id)
        setAlbumId(id)
    }

    const deleteAlbumId = (id) => {
        console.log(tracks)
        tracks.forEach(({id:trackId}) => {
            console.log(trackId)
            tracksRef.doc(trackId).delete()
            albumsRef.doc(id).delete()
        })
    }

    const addTrack = useCallback(async (track) => {
        const snaps = await albumsRef.where('name', '==', track.album).get();

        console.log('snaps', snaps)

        let albumId

        snaps.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());
            albumId = doc.id
        });
        if (!albumId) {


            const { picture, ...valid } = track

            const imgId = nanoid();
            const cover = picture?.[0];

            const format = cover?.format?.split('/')[1];

            let url;
            if (cover) {
                url = await uploadFile(cover.data, `${imgId}.${format}`);
            }

            const album = {
                name: track.album,
                artist: track.albumartist ?? track.artist, 
                cover: url,
            }

            console.log('GA', album)
            console.log('GA', track)

            const res = await albumsRef.add(album)
            albumId = res.id
        }
        const { picture, ...valid } = track

        console.log('VALID', valid)
        await tracksRef.add({
            ...valid,
            albumId
        })
    }, [albums])


    return {
        addTrack,
        albums,
        changeAlbumId,
        tracks,
        deleteAlbumId
    }
}

export default useFirestore